import ReactDOM from 'react-dom';
import "./css/index.css"
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
    <App />,
  document.getElementById('root')
);
